<template>
  <header
    class="header"
    :class="{ isDashboard: $route.meta.isAdmin, minimized: !isNavbarMinimized }"
  >
    <div class="header__container container">
      <div class="header__inner">
        <h1
          v-if="$route.meta.isReactiveHeaderTitle"
          id="header__title"
          class="header__title"
        ></h1>
        <h1 v-else-if="$route.meta.headerTitle" class="header__title">
          {{ $route.meta.headerTitle }}
        </h1>
        <div
          v-else
          style="
            background-color: #fff;
            padding: 45px 0 12px 0;
            width: 100%;
            height: 69px;
          "
        ></div>
        <!-- <div
          v-else
          class="header__search-wrapper"
          :class="{
            isSearch:
              state.searchResults.length > 0 &&
              state.isSearchFocused &&
              state.searchQuery?.length,
          }"
        >
          <inline-svg
            :src="require('@/assets/images/search.svg')"
            fill="#fff"
          />

          <input
            class="header__search"
            type="search"
            placeholder="Поиск"
            v-model="state.searchQuery"
            @focus="state.isSearchFocused = true"
            @blur="state.isSearchFocused = false"
          />

          <ul
            class="header__search-results"
            :class="{
              isSearch:
                state.searchResults.length > 0 &&
                state.isSearchFocused &&
                state.searchQuery?.length,
            }"
          >
            <li v-for="result in state.searchResults" :key="result.id">
              {{ result.title }}
            </li>
          </ul>
        </div> -->

        <div class="header__toolbar">
          <NotificationList />

          <button
            type="button"
            class="header__profile"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img
              class="header__profile-avatar"
              src="@/assets/images/avatar.jpg"
              alt="avatar"
            />

            <div class="header__profile-inner">
              <span class="header__profile-name"> {{ userData?.name }} </span>
              <span class="header__profile-role">
                {{ userData?.role?.title }}
              </span>
            </div>
          </button>

          <ul
            class="dropdown-menu dropdown-menu-end dropdown-dark"
            style="z-index: 1050"
          >
            <li>
              <router-link
                :to="{
                  name: 'profile-page',
                }"
                class="dropdown-item"
              >
                Профиль
              </router-link>
            </li>
            <li>
              <button @click="logout" type="button" class="dropdown-item">
                Выйти из системы
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { reactive, computed, watch } from "vue";
import { useStore } from "vuex";

import NotificationList from "@/components/header/NotificationList";

export default {
  name: "Header",
  components: { NotificationList },
  setup() {
    const store = useStore();
    const isNavbarMinimized = computed(() => store.getters.isNavbarMinimized);
    const userData = computed(() => store.getters.user);
    const state = reactive({
      searchQuery: null,
      searchResults: [],
      isSearchFocused: false,
    });

    watch(
      () => state.searchQuery,
      () => {
        onSearch();
      }
    );

    const onSearch = () => {
      state.searchResults = [];

      if (state.searchQuery)
        setTimeout(() => {
          state.searchResults = [{ id: 0, title: "Input text" }];
        }, 1000);
    };

    const logout = () => store.dispatch("logout");

    return { state, userData, isNavbarMinimized, logout };
  },
};
</script>

<style lang="scss" scoped>
.header {
  &.isDashboard {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1049;
    margin-left: 72px;
    padding-bottom: 0;
    background-color: var(--main-bg-color);

    &.minimized {
      margin-left: 268px;
    }

    & .header__inner {
      background-color: var(--main-white);
    }

    & .header__container {
      padding: 0 30px;
    }
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 45px 0 12px 0;
  }

  &__container {
    padding: 0;
  }

  &__title {
    @extend %gr-bold;
  }

  &__toolbar {
    display: flex;
    align-items: center;
  }

  &__profile {
    display: flex;
    align-items: center;
    margin-left: 24px;
    padding-right: 12px;
    background-color: transparent;
    border: none;
    border-radius: 0;
  }

  &__profile-avatar {
    border-radius: 100%;
    min-height: 36px;
    min-width: 36px;
    height: 36px;
    width: 36px;
    object-fit: cover;
  }

  &__profile-inner {
    margin-left: 14px;
    text-align: left;
  }

  &__profile-name {
    @extend %gr-bold;
    display: block;
    color: var(--main-black);
  }

  &__profile-role {
    color: var(--main-black);
    opacity: 0.5;
    white-space: nowrap;
  }

  &__search-wrapper {
    position: relative;
    width: 100%;
    margin-right: 24px;

    &.isSearch {
      background-color: var(--main-black);
      border-radius: 44px 44px 0 0;
    }

    svg {
      position: absolute;
      left: 16px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__search {
    width: 100%;
    background-color: var(--main-black);
    border: 1px solid var(--main-medium-grey);
    padding: 20px 20px 20px 55px;
    border-radius: 44px;
    font-size: 18px;
    color: var(--main-white);
    @extend %gr-regular;

    &::placeholder {
      font-size: 18px;
      opacity: 1;
      color: var(--main-white);
    }
  }

  &__search-results {
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    background-color: var(--main-black);
    color: var(--main-white);
    padding-top: 10px;
    padding-bottom: 20px;
    border-radius: 0 0 44px 44px;

    &.isSearch {
      display: block;
    }

    li {
      padding: 12px 20px;
      cursor: pointer;
    }
  }
}
</style>