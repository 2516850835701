<template>
  <nav class="main-navbar" :class="{ minimized: isNavbarMinimized }">
    <div class="main-navbar__top">
      <router-link
        :to="{
          name: 'dashboard',
        }"
        class="main-navbar__logo-link"
      >
        <img
          class="main-navbar__logo-small"
          src="@/assets/images/logo-small.svg"
          alt="logo"
        />
        <img
          class="main-navbar__logo-text"
          src="@/assets/images/logo-text.svg"
          alt="logo text"
        />
      </router-link>

      <span class="line-separator"></span>

      <ul class="main-navbar__menu">
        <li v-if="isAnalyticsActive" class="main-navbar__menu-item">
          <router-link
            :to="{
              name: 'analytics-page',
            }"
          >
            <span
              class="main-navbar__svg-wrapper main-navbar__svg-wrapper--stroke"
            >
              <inline-svg :src="require('@/assets/images/graph.svg')" />
            </span>

            <span class="main-navbar__menu-item-text">Аналитика</span>
          </router-link>
        </li>

        <li class="main-navbar__menu-item">
          <router-link
            :to="{
              name: 'dashboard',
            }"
          >
            <span class="main-navbar__svg-wrapper">
              <inline-svg :src="require('@/assets/images/home.svg')" />
            </span>

            <span class="main-navbar__menu-item-text">Главное меню</span>
          </router-link>
        </li>

        <li class="main-navbar__menu-item">
          <router-link
            :to="{
              name: 'applications-page',
            }"
            :class="{
              'router-link-active':
                $route.name == 'formation-page' ||
                $route.name == 'application-plan-page',
            }"
          >
            <span class="main-navbar__svg-wrapper">
              <inline-svg :src="require('@/assets/images/file.svg')" />
            </span>

            <span class="main-navbar__menu-item-text">Заявки</span>
          </router-link>
        </li>

        <li class="main-navbar__menu-item">
          <router-link
            :to="{
              name: 'plan-page',
            }"
            :class="{ 'router-link-active': $route.name == 'plan-task-page' }"
          >
            <span class="main-navbar__svg-wrapper">
              <inline-svg :src="require('@/assets/images/shapes.svg')" />
            </span>

            <span class="main-navbar__menu-item-text">План/прогресс</span>
          </router-link>
        </li>

        <li class="main-navbar__menu-item">
          <router-link
            :to="{
              name: 'depot-page',
            }"
            :class="{
              'router-link-active':
                $route.name == 'materials-page' ||
                $route.name == 'reserve-products-page',
            }"
          >
            <span
              class="main-navbar__svg-wrapper main-navbar__svg-wrapper--stroke"
            >
              <inline-svg :src="require('@/assets/images/box.svg')" />
            </span>

            <span class="main-navbar__menu-item-text">Склад</span>
          </router-link>
        </li>

        <li v-if="isAccoutingActive" class="main-navbar__menu-item">
          <router-link
            :to="{
              name: 'accounting-page',
            }"
          >
            <span
              class="main-navbar__svg-wrapper main-navbar__svg-wrapper--stroke"
            >
              <inline-svg :src="require('@/assets/images/tenge.svg')" />
            </span>

            <span class="main-navbar__menu-item-text">Бухгалтерский учет</span>
          </router-link>
        </li>
      </ul>

      <span class="line-separator"></span>
    </div>

    <div class="main-navbar__bottom">
      <router-link
        :to="{
          name: 'profile-page',
        }"
        class="main-navbar__profile-link"
      >
        <img src="@/assets/images/avatar.jpg" alt="avatar" />
        <span>Профиль</span>
      </router-link>

      <button
        class="main-navbar__menu-toggler"
        :class="{ active: isNavbarMinimized }"
        @click="toogleNavbar"
      >
        <inline-svg :src="require('@/assets/images/right-arrows.svg')" />
      </button>
    </div>
  </nav>
</template>

<script>
import { reactive, computed, watch } from "vue";
import { useStore } from "vuex";

export default {
  name: "NavBar",
  setup() {
    const store = useStore();
    const isNavbarMinimized = computed(() => store.getters.isNavbarMinimized);
    const toogleNavbar = () => store.commit("toggleNavbar");
    const showNavbar = () => store.commit("showNavbar");
    const userPermissions = computed(() => store.getters.getUserPermissions);
    const isAccoutingActive = computed(
      () => userPermissions.value["32"]?.permissions["35"] ?? false
    );
    const isAnalyticsActive = computed(() =>
      userPermissions.value["23"]?.permissions
        ? Object.keys(userPermissions.value["23"]?.permissions).length > 0
        : false
    );

    const state = reactive({
      searchQuery: null,
      searchResults: [],
      isSearchFocused: false,
    });

    watch(
      () => state.searchQuery,
      () => {
        onSearch();
      }
    );

    const onSearch = () => {
      state.searchResults = [];

      if (state.searchQuery)
        setTimeout(() => {
          state.searchResults = [
            { id: 0, title: "Input text" },
            { id: 1, title: "Input text 1" },
          ];
        }, 1000);
    };

    return {
      state,
      isNavbarMinimized,
      isAccoutingActive,
      isAnalyticsActive,
      toogleNavbar,
      showNavbar,
    };
  },
};
</script>

<style scoped lang="scss">
.main-navbar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  background-color: var(--main-black);
  max-width: 268px;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  color: var(--main-light-grey);
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: rgba(33, 35, 38, 0.4) 3px 0 12px 3px;
  transition: all 0.3s ease-out, height 0s;

  &__logo-link {
    display: flex;
    align-items: flex-end;
    width: max-content;
    margin: 18px;
    margin-bottom: 32px;
  }

  &__logo-small {
    height: 28px;
  }

  &__logo-text {
    height: 17px;
    margin-left: 5px;
  }

  &__svg-wrapper {
    width: 24px;
    height: 24px;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    svg {
      fill: var(--main-light-grey);
    }

    &.main-navbar__svg-wrapper--stroke svg {
      stroke: var(--main-light-grey);
      fill: none;
    }
  }

  &__search-wrapper {
    display: inline-grid;
    grid-template-columns: max-content 1fr;
    align-items: center;
    margin: 0 18px 0 23px;
    margin-bottom: 15px;
    position: relative;

    & label {
      cursor: pointer;
    }
  }

  &__search {
    background-color: transparent;
    border: none;
    font-size: 14px;
    color: var(--main-white);
    @extend %gr-regular;
    padding: 0;
    margin-left: 4px;

    &::placeholder {
      color: var(--main-light-grey);
      opacity: 1;
    }
  }

  &__search-results {
    display: none;
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    top: calc(100% + 10px);
    background-color: var(--main-white);
    padding: 8px 0;
    border-radius: 5px;

    &.isSearch {
      display: block;
    }

    li {
      font-size: 13px;
      color: var(--main-font-color);
      @extend %gr-medium;
      padding: 8px 20px;
      cursor: pointer;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      &:hover {
        background-color: var(--main-input-grey);
      }
    }
  }

  &__menu-item {
    & a {
      width: 100%;
      display: grid;
      align-items: center;
      grid-template-columns: max-content 1fr;
      white-space: nowrap;
      padding: 10px 18px 10px 23px;
      position: relative;

      &:hover {
        color: var(--main-white);

        & .main-navbar__svg-wrapper svg {
          fill: var(--main-white);
        }
        & .main-navbar__svg-wrapper--stroke svg {
          fill: none;
          stroke: var(--main-white);
        }
      }

      &.router-link-active {
        color: var(--main-white);

        &::before {
          content: "";
          position: absolute;
          width: 3px;
          height: 32px;
          background-color: var(--main-gold-yellow);
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }

        & .main-navbar__svg-wrapper svg {
          fill: var(--main-white);
        }

        & .main-navbar__svg-wrapper.main-navbar__svg-wrapper--stroke svg {
          fill: none;
          stroke: var(--main-white);
        }
      }
    }

    & .main-navbar__menu-item-text {
      margin-left: 4px;
      white-space: nowrap;
    }
  }

  &__bottom {
    padding: 18px;
    border-top: 1px solid var(--main-grey);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__profile-link {
    display: flex;
    align-items: center;
    width: 100%;

    img {
      width: 36px;
      height: 36px;
      border-radius: 100%;
      object-fit: cover;
    }

    span {
      margin-left: 8px;
    }

    &:hover span {
      color: var(--main-white);
    }
  }

  &__menu-toggler {
    padding: 4px;
    background-color: var(--main-grey);
    border: none;
    border-radius: 2px;
    width: 22px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease-out, transform 0.2s ease-out;

    &.active {
      background-color: var(--main-gold-yellow);
      transform: rotate(-180deg);
    }
  }

  &.minimized {
    max-width: 72px;
    overflow-x: hidden;
    box-shadow: none;

    .main-navbar__logo-text,
    .main-navbar__search,
    .main-navbar__menu-item-text,
    .main-navbar__profile-link span,
    .main-navbar__menu-toggler {
      opacity: 0;
      display: none;
    }
  }

  &.minimized:hover {
    max-width: 268px;

    .main-navbar__logo-text,
    .main-navbar__search,
    .main-navbar__menu-item-text,
    .main-navbar__profile-link span,
    .main-navbar__menu-toggler {
      opacity: 1;
      display: inline-block;
    }
    .main-navbar__menu-toggler {
      width: 22px;
      height: 24px;
      display: flex;
    }
  }
}

.main-navbar.minimized:hover ~ .main-layout {
  padding-left: 72px !important;
}
</style>
