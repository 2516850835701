<template>
  <div class="main-layout" :class="{ minimized: !isNavbarMinimized }">
    <Navbar />

    <div class="container">
      <Header />

      <router-view />
    </div>
  </div>
</template>

<script>
import { computed, onMounted } from "vue";
import { useStore } from "vuex";

import Navbar from "@/layout/Navbar.vue";
import Header from "@/layout/Header.vue";

export default {
  name: "main-layout",
  components: {
    Navbar,
    Header,
  },
  setup() {
    const store = useStore();
    const isNavbarMinimized = computed(() => store.getters.isNavbarMinimized);

    onMounted(() => {
      store.dispatch("getUserPermissions");
    });

    return {
      isNavbarMinimized,
    };
  },
};
</script>

<style lang="scss">
.main-layout {
  display: flex;
  flex: 1 0 auto;
  flex-direction: row;
  align-items: flex-start;
  padding-left: 72px;

  &.minimized {
    padding-left: 268px;
  }
}
</style>