import { ref, onMounted, onUnmounted } from 'vue';

export default function useNotification() {
  const isMenuOpen = ref(false);
  const menuRef = ref(null);

  const menuClickHandler = (e) => {
    if (e.target != menuRef.value) isMenuOpen.value = false;
  };

  onMounted(() => {
    addEventListener('click', menuClickHandler);
  });

  onUnmounted(() => {
    removeEventListener('click', menuClickHandler);
  });

  return { menuRef, isMenuOpen };
}
