<template>
  <div class="dropdown-filter position-relative">
    <button
      type="button"
      ref="menuRef"
      id="main-dropdown-notifications"
      class="notifications__btn"
      :class="{ active: notifications.length }"
      @click="isMenuOpen = !isMenuOpen"
    >
      <inline-svg
        :src="require('@/assets/images/notification.svg')"
        fill="#F8F8F8"
        style="pointer-events: none"
      />
    </button>

    <ul
      class="dropdown-menu dropdown-menu-end notifications__list end-0"
      :class="{ show: isMenuOpen }"
    >
      <h5 class="notifications__title">Уведомление</h5>

      <div class="notifications__list-inner scrollbar-dark">
        <TransitionGroup name="list" tag="div">
          <li v-for="notification in notifications" :key="notification.task_id">
            <div class="notifications__item">
              <span
                class="main-circle circle-extra-sm danger me-1"
                style="margin-top: 6px"
              ></span>
              <div class="d-flex flex-column">
                <h5 class="notifications__item-title">
                  {{ notification.message }}. Номер задачи:
                  {{ notification.task_id }}
                </h5>
                <span class="notifications__item-text">{{
                  formatDateTime(notification.updated_at)
                }}</span>
              </div>

              <div class="notifications__item-btn-wrapper dropstart">
                <button
                  @click.stop
                  class="notifications__item-btn"
                  type="button"
                  id="main-dropdown-notification-item"
                  data-toggle="dropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <inline-svg
                    :src="require('@/assets/images/dots.svg')"
                    fill="#D9D9D9"
                  />
                </button>
                <div
                  class="dropdown-menu"
                  aria-labelledby="main-dropdown-notification-item"
                >
                  <button
                    @click.stop="checkNotification(notification.task_id)"
                    class="dropdown-item"
                    type="button"
                  >
                    Отметить как прочитанное
                  </button>
                </div>
              </div>
            </div>
          </li>
        </TransitionGroup>

        <Transition>
          <li v-if="!notifications.length" class="mw-100" style="width: 450px">
            <span
              class="d-block notifications__item-title border-line-separator border-line-separator--top px-5 py-4 mt-2"
              >Ничего не найдено!</span
            >
          </li>
        </Transition>
      </div>
    </ul>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted, computed } from "vue";
import { useStore } from "vuex";
import Pusher from "pusher-js";

import { formatDateTime } from "@/helpers/dateTimeUtils.js";
import { newNotificationAlert } from "@/helpers/alerts";
import useNotification from "@/mixins/useNotification";

export default {
  setup() {
    const { menuRef, isMenuOpen } = useNotification();
    const store = useStore();
    const userId = computed(() => store.getters.userId);
    const myStorage = window.localStorage;
    const localNotifications = myStorage.getItem("alem-notifications")
      ? JSON.parse(myStorage.getItem("alem-notifications"))
      : [];
    const notifications = ref(localNotifications);

    Pusher.log = function (msg) {
      console.log(msg);
    };

    const pusher = new Pusher("9f6e2926f68e6ae7c8ca", {
      cluster: "ap2",
    });
    const channel = pusher.subscribe(`user.${userId.value}`);

    const updateStorage = () => {
      myStorage.setItem(
        "alem-notifications",
        JSON.stringify(notifications.value)
      );
    };

    const checkNotification = (id) => {
      notifications.value = notifications.value.filter(
        (item) => item.task_id != id
      );
      updateStorage();
    };

    const handleEvent = (data) => {
      notifications.value.push(data);
      updateStorage();
      newNotificationAlert();
    };

    onMounted(() => {
      channel.bind("task-status-changed", handleEvent);
    });

    onUnmounted(() => {
      channel.unbind("task-status-changed", handleEvent);
      pusher.unsubscribe(`user.${userId.value}`);
    });

    return {
      menuRef,
      isMenuOpen,
      notifications,
      formatDateTime,
      checkNotification,
    };
  },
};
</script>

<style lang="scss" scoped>
.notifications {
  &__btn {
    background-color: var(--main-black);
    border: none;
    border-radius: 100%;
    min-height: 32px;
    min-width: 32px;
    height: 32px;
    width: 32px;
    max-height: 32px;
    max-width: 32px;
    display: block;
    padding: 4px;
    padding-top: 1px;
    cursor: pointer;
    position: relative;

    &.active {
      &::before {
        content: "";
        position: absolute;
        top: 7px;
        right: 8px;
        width: 5px;
        height: 5px;
        border-radius: 100%;
        background-color: var(--main-danger);
      }
    }
  }

  &__list {
    z-index: 1050;
    background-color: var(--main-dropdown-hover);
    border: none;
    border-radius: 8px;
    max-width: 450px;
    width: 100vw;
    color: var(--main-white);
    top: calc(100% + 5px);
  }

  &__list-inner {
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &__title {
    font-size: 17px;
    padding: 8px 46px;
  }

  &__item {
    display: flex;
    align-items: flex-start;
    padding: 19px 46px;
    border-top: 1px solid var(--main-select-background);
    position: relative;
    transition: all 0.2s ease-out;

    &:hover {
      background-color: var(--main-dropdown);
      padding: 19px 20px;
      padding-right: 72px;

      & > .notifications__item-btn-wrapper {
        display: block;
      }
    }
  }

  &__item-title {
    font-size: 17px;
  }

  &__item-text {
    opacity: 0.5;
    font-size: 13px;
  }

  &__item-btn-wrapper {
    display: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    z-index: 1;

    & .dropdown-item {
      &:hover {
        background-color: var(--main-input-grey);
      }
    }
  }

  &__item-btn {
    background-color: transparent;
    border: none;
  }
}
</style>

<style lang="scss">
.list-enter-active,
.list-leave-active {
  transition: all 0.2s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(20px);
}

.v-enter-active {
  transition: opacity 1.5s ease;
}
.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>